<template>
  <span>
    <v-btn x-small icon @click="dialog = !dialog">
      <v-icon color="neuter"> mdi-cog </v-icon>
    </v-btn>

    <!--Dialog Options-->
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent width="800px">
        <v-card>
          <v-card-title class="headline secondary white--text">{{ getTitle() }}</v-card-title>
          <v-card-text class="pa-8">
            <v-row v-if="type === 'TABLE'">
              <v-col cols="12">
                <v-autocomplete
                  :disabled="
                    !(structuresDetails && structuresDetails.length > 0)
                  "
                  small
                  flat
                  solo
                  background-color="#EAEAEA80"
                  multiple
                  item-color="secondary"
                  :label="$t('structure.titles.details')"
                  :items="structuresDetails"
                  return-object
                  item-text="structure_name"
                  item-value="structure_id"
                  v-model="selected"
                  color="secondary"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip small v-if="index === 0">
                      <span>{{ item.structure_name }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ selected.length - 1 }}
                      {{ $t('general.titles.others') }})
                    </span>
                  </template>
                  <template v-slot:item="{ item, attrs }">
                    <div style="max-width: 360px">
                      <v-checkbox
                        :input-value="attrs.inputValue"
                        color="secondary"
                        class="pt-2 pb-2 mt-0"
                        :hide-details="true"
                      >
                        <template v-slot:label>
                          <read-more
                            :classes="'mb-0'"
                            :text="item.structure_name"
                            :max-chars="80"
                            :font-size="14"
                          />
                        </template>
                      </v-checkbox>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                class="d-flex flex-wrap justify-start align-start"
              >
                <v-chip-group
                  v-model="selection"
                  column
                  active-class="primary--text"
                >
                  <draggable v-model="selected" @start="dragStart" @end="dragEnd">
                    <v-chip
                      @click:close="deleteItem(index)"
                      close
                      class="ma-1"
                      small
                      v-for="(item, index) in selected"
                      :key="index"
                      draggable
                      >
                      {{ item.structure_name }}
                    </v-chip>
                  </draggable>
                </v-chip-group>
              </v-col>
            </v-row>
            <v-row v-else-if="type === 'REPORTS'">
              <v-col
                cols="12"
                class="
                  d-flex
                  justify-space-between
                  align-content-center align-center
                "
              >
                <v-label class="title" ref="label"
                  >{{ $t('reports.titles.user-data') }}
                </v-label>
                <v-switch v-model="userData" color="secondary" />
              </v-col>
            </v-row>
            <v-row v-else-if="type === 'CONFIG'">
              <v-form ref="formCompany" v-model="form.company">
                <v-col
                    cols="12"
                    class="ma-4"
                >
                  <v-btn-toggle
                      v-model="tab"
                      color="secondary"
                      borderless
                  >
                    <v-btn :value="0">
                      {{ $t('general.titles.general') }}
                    </v-btn>

                    <v-btn :value="1" v-if="profile.company_id === 1">
                      Blockchain
                    </v-btn>

                    <v-btn :value="2">
                      {{ $t('general.buttons.export') }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="12">
                  <v-tabs-items v-model="tab" >
                    <v-tab-item class="mx-4">
                      <v-label>
                        {{ $t('company.titles.decimalNumbers') }}
                      </v-label>
                      <v-text-field
                          :placeholder="(company.traceability.structure.number.afterPoint === null ||
                                     company.traceability.structure.number.afterPoint === '')
                                       ? $t('company.titles.defaultValue')
                                       : ''"
                          id="afterPoint"
                          dense
                          filled
                          flat
                          color="secondary"
                          type="number"
                          clearable
                          :rules="compareMax(0,5)"
                          v-model="company.traceability.structure.number.afterPoint"
                      >
                      </v-text-field>
                    </v-tab-item>
                    <v-tab-item>
                      <FormWalletComponent @isValid="form.company = $event"/>
                      <v-row>
                        <v-col cols="12">
                          <WalletsTable />
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item class="mx-4">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-label>
                            {{ $t('modules.name.Record') }}
                          </v-label>
                          <v-text-field
                            :placeholder="(company.export.recordExportLimit === null ||
                                           company.export.recordExportLimit === '')
                                            ? $t('company.titles.defaultExportRecordsValue')
                                            : ''"
                            id="exportRecordLimit"
                            dense
                            filled
                            flat
                            color="secondary"
                            type="number"
                            clearable
                            v-model="company.export.recordExportLimit"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-label>
                            {{ $t('modules.name.Survey') }}
                          </v-label>
                          <v-text-field
                            :placeholder="(company.export.inspectionExportLimit === null ||
                                           company.export.inspectionExportLimit === '')
                                            ? $t('company.titles.defaultExportInspectionsValue')
                                            : ''"
                            id="exportRecordLimit"
                            dense
                            filled
                            flat
                            color="secondary"
                            type="number"
                            clearable
                            v-model="company.export.inspectionExportLimit"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>

                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-form>
            </v-row>
          </v-card-text>
          <v-card-actions class="mx-3">
            <v-btn
              small
              text
              color="gray"
              v-if="[0,2].includes(tab)"
              @click="resetConfig()"
              :disabled="loadingCancel || loading"
              :loading="loadingReset"
            >
              {{ $t('general.buttons.clean') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              small
              text
              color="gray"
              @click="cancelDialogConfig()"
              :loading="loadingCancel"
              :disabled="loading || loadingReset"
            >
              {{ $t('general.buttons.cancel') }}
            </v-btn>
            <v-btn
              small
              text
              color="secondary"
              v-if="[0,2].includes(tab)"
              @click="confirm()"
              :loading="loading"
              :disabled="loadingReset || loadingCancel || module === 'COMPANY' ? !form.company : false"
            >
              {{ $t('general.buttons.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </span>
</template>

<script>
  import _ from 'lodash'
  import i18n from '@/plugins/i18n'
  import { mapGetters, mapActions } from 'vuex'
  import draggable from 'vuedraggable'
  import WalletsTable from "@/components/UserOptions/Blockcahin/WalletsTable";
  import ReadMore from '../ReadMoreComponent'
  import LanguajeService from '@/services/LanguajeService'
  import CompanyService from "@/services/CompanyService";
  import FormWalletComponent from "@/components/UserOptions/Blockcahin/WalletFormComponent";

  export default {
    name: 'UserOptions',
    components: { FormWalletComponent, WalletsTable, ReadMore, draggable },
    props: {
      stage: {
        type: Number,
        required: false,
      },
      module: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      dialog: false,
      tab: 0,
      selected: [],
      processed: [],
      loading: false,
      loadingReset: false,
      loadingCancel: false,
      configUser: {},
      profile: JSON.parse(localStorage.getItem('profile')),
      company: {
        export: {
          recordExportLimit: null,
          inspectionExportLimit: null
        },
        traceability: {
          structure: {
            number: {
              afterPoint: null
            }
          }
        }
      },
      userData: false,
      form: {
        company: false
      },
      selection: null,
      currentTag: null
    }),

    async created() {
      this.configUser = JSON.parse(localStorage.getItem('config'))
      console.log(this.configUser)
      if (this.module === 'general') {
        this.userData = this.configUser.reports.general.userData
      }
      if(this.module === 'company') {
        this.company.traceability.structure.number = this.configUser.company.traceability.structure.number
        this.company.export.recordExportLimit = this.configUser.company.export.recordExportLimit
        this.company.export.inspectionExportLimit = this.configUser.company.export.inspectionExportLimit
      }
      await this.updateOptions(null)
    },

    async mounted() {
      if (this.module === 'records') {
        this.$root.$on('updateOptions', async (mehtod = null) => {
          console.log(mehtod)
          await this.updateOptions(mehtod);
        })
      }
    },

    watch: {
      selected() {
        this.process()
        if (this.module === 'records') {
          const stage = _.find(this.storageTypes, {
            storage_device_type_id: this.stage,
          })
          const id = stage.storage_device_type_id
          this.configUser.structures.byGroup[id] = {
            headers: this.selected,
            processed: this.processed,
          }
        } else if (this.module === 'traceability') {
          this.configUser.structures.simples = {
            headers: this.selected,
            processed: this.processed,
          }
        }
        localStorage.setItem(`config`, JSON.stringify(this.configUser))
      },
      userData() {
        if (this.module === 'general') {
          this.configUser.reports.general.userData = this.userData
          localStorage.setItem(`config`, JSON.stringify(this.configUser))
        }
      }
    },

    computed: {
      config: {
        get() {
          return this.$store.getters['general/config']
        },
        set(val) {
          this.$store.commit('general/SET_CONFIG', val)
        },
      },
      headersRecords: {
        get() {
          return this.$store.getters['records/headers']
        },
        set(val) {
          this.$store.commit('records/SET_HEADERS', val)
        },
      },
      headersTraceability: {
        get() {
          return this.$store.getters['reports/headersTraceability']
        },
        set(val) {
          this.$store.commit('reports/SET_HEADERS_TRACEABILITY', val)
        },
      },
      ...mapGetters({
        defaultHeadersRecords: 'records/defaultHeaders',
        defaultHeadersTraceability: 'reports/defaultHeadersTraceability',
        structuresDetails: 'records/structures',
        storageTypes: 'storage_type/storageDeviceTypesActiveByCompany',
        compareMax: 'general/compareMax'
      }),
    },

    methods: {
      ...mapActions({
        createConfigUser: 'authority/createConfigUser',
        loadConfigUser: 'authority/loadConfigUser',
      }),

      getTitle() {
        switch (this.type) {
          case 'TABLE':
            return i18n.t('general.titles.headers')
          case 'REPORTS':
            return i18n.t('reports.titles.general-report')
          case 'CONFIG':
            return i18n.t('company.titles.configurations')
        }
      },

      async updateOptions(method) {
        if (this.module === 'records') {
          const stage = _.find(this.storageTypes, {
            storage_device_type_id: this.stage,
          })

          if (stage) {
            let headers = null
            if (method === 'cancel') {
              this.configUser = JSON.parse(localStorage.getItem('config'))
              headers = this.configUser.structures.byGroup[
                stage.storage_device_type_id
              ]
                ? this.configUser.structures.byGroup[
                    stage.storage_device_type_id
                  ].headers
                : []
            } else {
              headers = this.configUser.structures.byGroup[
                stage.storage_device_type_id
              ]
                ? this.configUser.structures.byGroup[
                    stage.storage_device_type_id
                  ].headers
                : []
            }

            if (headers && headers.length > 0) {
              const id = stage.storage_device_type_id
              this.selected =
                this.configUser.structures.byGroup[id].headers &&
                this.configUser.structures.byGroup[id].headers.length > 0
                  ? this.configUser.structures.byGroup[id].headers
                  : []
            } else {
              this.selected = []
            }
          } else {
            this.selected = []
          }
        } else if (this.module === 'traceability') {
          if (method === 'cancel') {
            this.configUser = JSON.parse(localStorage.getItem('config'))
            this.selected = this.configUser.structures.simples.headers
          } else {
            this.selected = this.configUser.structures.simples.headers
          }
        }
      },

      deleteItem(index) {
        this.selected.splice(index, 1)
      },

      process() {
        this.processed = _.map(this.selected, structure => {
          return {
            text: this.translate(structure.language_key),
            value: this.translate(structure.language_key),
            val: this.translate(structure.language_key),
            type: 'text',
            width: this.translate(structure.language_key).length > 10 ?
                   this.translate(structure.language_key).length * 10 : 150,
            sortable: false,
          }
        })
      },

      translate(key, uppercase) {
        return LanguajeService.getKey3(key)
      },

      getByProperty(array, property) {
        return _.map(array, property)
      },

      async resetConfig() {
        this.loadingReset = true

        const config = this.manageModule(null, 'reset')

        await this.createConfigUser([config, this.$toast]).finally(() => {
          this.loadingReset = false
          this.dialog = false
        })

        await this.launchModule()
      },

      async confirm() {
        this.loading = true


        if(['traceability', 'records'].includes(this.module)) {
          //-------------
          this.process()
          //-------------
        }

        const byGroup = {
          storage_device_type_id: this.stage,
          structures_id: this.getByProperty(this.selected, 'id'),
        }

        const config = this.manageModule(byGroup, 'create')
        //-------------

        if(this.module === 'company') {
          this.configUser.company.export.recordExportLimit = config.recordExportLimit;
          this.configUser.company.export.inspectionExportLimit = config.inspectionExportLimit;

          await CompanyService.setConfig(config).finally(() => {
            this.loading = false
            this.dialog = false
          })
        } else {
          await this.createConfigUser([config, this.$toast]).finally(() => {
            this.loading = false
            this.dialog = false
          })
        }

        localStorage.setItem(`config`, JSON.stringify(this.configUser))

        await this.launchModule()
      },

      async launchModule() {
        switch (this.module) {
          case 'records':
            this.$root.$emit('getResultsPaginatedByCompany')
            return
          case 'traceability':
            this.$root.$emit('getResults')
            return
        }
      },

      manageModule(byGroup, method) {
        let config = {}

        if (method === 'create') {
          switch (this.module) {
            case 'records':
              config = {
                structures: {
                  by_storage_device_type: {
                    structures: _.cloneDeep(byGroup),
                  },
                  general: [],
                  config_general: false,
                  config_by_storage_device_type: true,
                },
              }
              return config
            case 'traceability':
              config = {
                structures: {
                  by_storage_device_type: {
                    structures: {
                      storage_device_type_id: null,
                      structures_id: [],
                    },
                  },
                  general: this.getByProperty(this.selected, 'id'),
                  config_general: true,
                  config_by_storage_device_type: false,
                },
              }
              return config
            case 'general':
              config = {
                structures: null,
                reports: {
                  general_report_config: {
                    configure: true,
                    show_company_data: this.userData,
                  },
                },
              }
              return config
            case 'company':
              config = {
                companyStructuresPreferences: {
                  afterPoint: this.company.traceability.structure.number.afterPoint
                },
                recordExportLimit: this.company.export.recordExportLimit,
                inspectionExportLimit: this.company.export.inspectionExportLimit
              }
              this.configUser.company.traceability.structure.number = this.company.traceability.structure.number
              localStorage.setItem(`config`, JSON.stringify(this.configUser))
              return config
          }
        } else if ('reset') {
          switch (this.module) {
            case 'records':
              const stage = _.find(this.storageTypes, {
                storage_device_type_id: this.stage,
              })
              const id = stage.storage_device_type_id

              config = {
                structures: {
                  by_storage_device_type: {
                    structures: {
                      storage_device_type_id: this.stage,
                      structures_id: [],
                    },
                  },
                  general: [],
                  config_general: false,
                  config_by_storage_device_type: true,
                },
              }

              this.configUser.structures.byGroup[id] = {
                headers: [],
                processed: [],
              }
              localStorage.setItem('config', JSON.stringify(this.configUser))
              this.config.structures.byGroup = {}
              this.headersRecords = this.defaultHeadersRecords
              this.selected = []
              this.processed = []

              return config
            case 'traceability':
              config = {
                structures: {
                  by_storage_device_type: {
                    structures: {
                      storage_device_type_id: this.stage,
                      structures_id: [],
                    },
                  },
                  general: [],
                  config_general: true,
                  config_by_storage_device_type: false,
                },
              }

              this.configUser.structures.simples = {
                headers: [],
                processed: [],
              }
              localStorage.setItem('config', JSON.stringify(this.configUser))
              this.config.structures.simples = {
                headers: [],
                processed: [],
              }
              this.headersTraceability = this.defaultHeadersTraceability
              this.selected = []
              this.processed = []

              return config
            case 'general':
              config = {
                structures: null,
                reports: {
                  general_report_config: {
                    configure: true,
                    show_company_data: true,
                  },
                },
              }
              return config
            case 'company':
              config = {
                companyStructuresPreferences: {
                  afterPoint: null
                },
                recordExportLimit: 0,
                inspectionExportLimit: 0
              }
              this.company.traceability.structure.number.afterPoint = null
              this.configUser.company.traceability.structure.number = this.company.traceability.structure.number
              localStorage.setItem(`config`, JSON.stringify(this.configUser))
              return config
          }
        }
      },
      async cancelDialogConfig() {
        this.loadingCancel = true
        if(this.module !== 'company') {
          await this.loadConfigUser([this.$toast]).finally(() => {
            this.loadingCancel = false
            this.dialog = false
          })
          await this.updateOptions('cancel')
        } else {
          this.company.traceability.structure.number.afterPoint = this.configUser.company.traceability.structure.number.afterPoint
          this.loadingCancel = false
          this.dialog = false
          this.$root.$emit('onCLoseForm')
        }
      },
      dragStart() {
        if (this.selected[this.selection]) this.currentTag = this.selected[this.selection].name;
        else this.currentTag = null;
      },
      dragEnd() {
        let self = this;
        if (this.currentTag) {
          this.selected.forEach((x, i) => {
            if (x.name === self.currentTag) self.selection = i;
          });
        }

      }
    },

    destroyed() {
      this.configUser = {}
      this.$root.$off('updateOptions')
    },
  }
</script>

<style scoped>

</style>
