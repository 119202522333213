<template>
  <v-data-table
      class="mx-6"
      :footer-props="{
          ...rowsPerPageItemsOptions,
          itemsPerPageText: $t('general.table.itemsPerPageText'),
          pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
      item-key="address"
      :headers="headers"
      :items="wallets"
      :loading="loading"
      :no-data-text="$t('general.ui.not_data_found')"
      :no-results-text="$t('general.ui.not_search_found')"
      :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-row class="d-flex justify-space-between align-baseline py-10">
          <v-col class="px-0" cols="6" lg="4" md="4" sm="6" xl="4">
            <v-text-field
                v-model="search"
                :clearable="true"
                :label="$t('general.titles.filter')"
                append-icon="mdi-magnify"
                color="secondary"
                dense
                filled
                flat
                rounded
                hide-details
                onkeypress="return (event.charCode != 34)"
                single-line
            />
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.address="{ item }">
      {{ item.address | truncateAddress(7) }}
    </template>
    <template v-slot:item.company="{ item }">
      {{ item.company.name }}
    </template>
    <template v-slot:item.action="{ item, index }">
      <EditWalletDialog :wallet="item"
                        @onWalletChange="changeItem(item, $event)"
      />

      <DeleteWalletDialog :item="item" :index="index" />
    </template>
  </v-data-table>
</template>

<script>
import i18n from "@/plugins/i18n";
import {mapGetters, mapActions} from "vuex";
import EditWalletDialog from "@/components/UserOptions/Blockcahin/WalletEditDialog";
import DeleteWalletDialog from "@/components/UserOptions/Blockcahin/WalletDeleteDialog";

export default {
  name: "WalletsTable",
  components: {DeleteWalletDialog, EditWalletDialog},
  data: () => ({
    profile: JSON.parse(localStorage.getItem('profile')),
    search: null,
    loading: false,
    rowsPerPageItemsOptions: {
      itemsPerPageOptions: [3, 5],
    },
  }),

  computed: {
    ...mapGetters({
      wallets: 'web3/GET_WALLETS'
    }),
    headers() {
      return [
        {
          text: 'Nombre', value: 'name', val: 'name', width: 120
    },
        {
          text: 'Cartera', value: 'address', val: 'address'
        },
        {
          text: 'Compannia', value: 'company', val: 'company'
        },
        {
          text: i18n.t('general.fields.action'), value: 'action', width: 150, sortable: false, align: 'start',
        },
      ]
    },
  },

  async mounted() {
    this.loading = true
    await this.fetchWallets()
    await this.fetchCompanies(this.$toast).finally(() => {
      this.loading = false
    });
  },

  methods: {
    ...mapActions({
      fetchWallets: 'web3/FETCH_WALLETS',
      fetchCompanies: 'company/fetchAllCompanies'
    }),
    changeItem(item, event) {
      this.$set(item, 'name', event.name)
      this.$set(item, 'wallet', event.wallet)
      this.$set(item, 'company', event.company)
    }
  }
}
</script>

<style scoped>
</style>