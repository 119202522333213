import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,{staticClass:"ma-4 form__border"},[_c(VCol,{attrs:{"cols":"12"}}),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"clearable":"","placeholder":_vm.$t('general.titles.name'),"dense":"","filled":"","flat":"","color":"secondary","rules":_vm.required,"hide-details":_vm.valid},model:{value:(_vm.wallet.name),callback:function ($$v) {_vm.$set(_vm.wallet, "name", $$v)},expression:"wallet.name"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"clearable":"","placeholder":_vm.$t('inventory.fields.wallet'),"dense":"","filled":"","flat":"","color":"secondary","rules":_vm.validateAddress,"hide-details":_vm.valid},model:{value:(_vm.wallet.address),callback:function ($$v) {_vm.$set(_vm.wallet, "address", $$v)},expression:"wallet.address"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VAutocomplete,{attrs:{"clearable":"","placeholder":_vm.$t('menu.company'),"dense":"","filled":"","flat":"","color":"secondary","items":_vm.companies,"rules":_vm.required,"item-text":"name","item-value":"id","item-color":"secondary","hide-details":_vm.valid},model:{value:(_vm.wallet.companyId),callback:function ($$v) {_vm.$set(_vm.wallet, "companyId", $$v)},expression:"wallet.companyId"}})],1),_c(VCol,{staticClass:"d-flex justify-end align-content-center align-center",attrs:{"cols":"6"}},[_c(VBtn,{class:_vm.valid ? '' : 'mb-6',attrs:{"elevation":"0","disabled":!_vm.valid || _vm.loading,"color":"secondary","loading":_vm.loading},on:{"click":function($event){return _vm.addNewWallet()}}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.save'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }