import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDataTable,{staticClass:"mx-6",attrs:{"footer-props":{
        ..._vm.rowsPerPageItemsOptions,
        itemsPerPageText: _vm.$t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + _vm.$t('general.table.pageText') + ' {2}',
    },"item-key":"address","headers":_vm.headers,"items":_vm.wallets,"loading":_vm.loading,"no-data-text":_vm.$t('general.ui.not_data_found'),"no-results-text":_vm.$t('general.ui.not_search_found'),"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VRow,{staticClass:"d-flex justify-space-between align-baseline py-10"},[_c(VCol,{staticClass:"px-0",attrs:{"cols":"6","lg":"4","md":"4","sm":"6","xl":"4"}},[_c(VTextField,{attrs:{"clearable":true,"label":_vm.$t('general.titles.filter'),"append-icon":"mdi-magnify","color":"secondary","dense":"","filled":"","flat":"","rounded":"","hide-details":"","onkeypress":"return (event.charCode != 34)","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VSpacer)],1)],1)]},proxy:true},{key:"item.address",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("truncateAddress")(item.address,7))+" ")]}},{key:"item.company",fn:function({ item }){return [_vm._v(" "+_vm._s(item.company.name)+" ")]}},{key:"item.action",fn:function({ item, index }){return [_c('EditWalletDialog',{attrs:{"wallet":item},on:{"onWalletChange":function($event){return _vm.changeItem(item, $event)}}}),_c('DeleteWalletDialog',{attrs:{"item":item,"index":index}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }