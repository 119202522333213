import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"neuter"}},'v-icon',attrs,false),on),[_vm._v(" mdi-trash-can-outline ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h6"},[_vm._v("Are you sure you want to delete this wallet?")]),_c(VCardText,[_c('strong',[_vm._v(_vm._s(_vm.item.name)+":")]),_vm._v(" "+_vm._s(_vm._f("truncateAddress")(_vm.item.address,7))+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"neuter","text":"","disabled":_vm.loading},on:{"click":_vm.closeAction}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"secondary","text":"","loading":_vm.loading},on:{"click":_vm.deleteAction}},[_vm._v("Delete")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }